import {
  ChatBubbleBottomCenterIcon,
  MagnifyingGlassIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { useCallback } from 'react';

import { useUser } from '~/providers';

import { Text } from '../_legacy/Typography';
import Chip from '../Chip';
import { FormStepTitle } from '../FormStepTitle';
import { SelectCardOutlined } from '../SelectCardOutlined';
import { FieldError } from './ToolFieldError';

import type { ToolType } from '~/models';

const functionalityValues = [
  {
    id: 'raffle_search',
    title: 'Search',
    description: 'Enable Raffle Search for this Tool',
    Icon: MagnifyingGlassIcon,
  },
  {
    id: 'raffle_chat',
    title: 'Chat',
    description: 'Enable Raffle Chat for this Tool',
    Icon: ChatBubbleBottomCenterIcon,
  },
] as const;
export type FunctionalityId = (typeof functionalityValues)[number]['id'];

type ToolFunctionalitySectionProps = {
  error: string;
  handleToolFunctionalityChange: (
    selectedFunctionality: FunctionalityId,
  ) => void;
  selectedFunctionality: FunctionalityId[];
  toolType: ToolType;
};
export const ToolFunctionalitySection = ({
  handleToolFunctionalityChange,
  selectedFunctionality,
  error,
  toolType,
}: ToolFunctionalitySectionProps) => {
  const {
    account: { features },
  } = useUser();

  const isFunctionalityAvailable = useCallback(
    (id: FunctionalityId) => {
      if (toolType === 'api') {
        return id !== 'raffle_chat';
      }
      return !!features[id]?.amount;
    },
    [features, toolType],
  );

  return (
    <>
      <FormStepTitle
        title="Choose the functionality of your Tool"
        description="Decide whether you want to enable search and/or chat"
      />

      <FieldError message={error} />

      <Text variant="faded" className="-mb-10 italic">
        Select at least one option
      </Text>

      <div className="flex w-full flex-col gap-4">
        {functionalityValues.map(({ id, title, description, Icon }) => (
          <SelectCardOutlined
            key={`func-${id}`}
            id={id}
            onClick={handleToolFunctionalityChange}
            selected={selectedFunctionality.includes(id)}
            className="!h-fit w-full items-center gap-3 !rounded-md border p-3"
            disabled={!isFunctionalityAvailable(id)}
          >
            <>
              <div className="flex aspect-square h-[54px] items-center justify-center !rounded-sm border border-neutral-250 bg-neutral-50 p-3">
                <Icon className="h-5 w-5" />
              </div>

              <div className="flex h-full w-full flex-col justify-evenly">
                <div className="flex justify-between gap-2">
                  <Text className="!text-[black]" weight="medium" size="normal">
                    {title}
                  </Text>
                  {!isFunctionalityAvailable(id) && (
                    <Chip
                      Icon={StarIcon}
                      iconClassName="!text-neutral-500"
                      textClassName="!text-neutral-500"
                      className="!py-0"
                    >
                      {toolType === 'api' && id === 'raffle_chat'
                        ? 'Not available for API'
                        : 'Upgrade plan'}
                    </Chip>
                  )}
                </div>
                <div>
                  <Text className="leading-[150%] text-neutral-450" size="base">
                    {description}
                  </Text>
                </div>
              </div>
            </>
          </SelectCardOutlined>
        ))}
      </div>
    </>
  );
};
