import { Link } from '@remix-run/react';
import clsx from 'clsx';

import { Button, Checkbox, DynamicTooltip, Text } from '~/components';
import { Badge } from '~/components/ui/badge';
import { sourceTypeDict, webscrapeCMS } from '~/utils';

import type { Source } from '~/models';

export type SourceCardProps = {
  disabled: boolean;
  displayViewContent?: boolean;
  onToggle: () => void;
  selected: boolean;
  source: {
    id: Source['id'];
    internal: Source['internal'];
    subtype?: Source['subtype'];
    title: Source['title'];
    type: Source['type'];
  };
};

const SourceCard = ({
  source: { id, title, internal, type, subtype },
  displayViewContent = false,
  selected,
  disabled,
  onToggle,
}: SourceCardProps) => {
  const { img, Icon, name } =
    subtype && webscrapeCMS.includes(subtype)
      ? {
          img: sourceTypeDict[subtype].imgUrl,
          Icon: undefined,
          name: sourceTypeDict[subtype].name,
        }
      : {
          img: sourceTypeDict[type].imgUrl,
          Icon: sourceTypeDict[type].Icon,
          name: sourceTypeDict[type].name,
        };

  return (
    <div className="group relative flex items-center justify-between border-b border-neutral-250 bg-neutral-0 px-4 py-3 transition-background first:rounded-t-lg last:rounded-b-lg last:!border-none hover:bg-neutral-50">
      <div className="flex max-w-[60%] items-center gap-2 lg:gap-4">
        <DynamicTooltip
          offset={{ x: 0, y: -30 }}
          content={<p className="text-sm !text-neutral-600">{name}</p>}
        >
          <div className="max-h-12 rounded-lg bg-muted p-3">
            {img && <img className="h-5 w-5" src={img} alt={type} />}

            {Icon && <Icon className="h-5 w-5 stroke-1" />}
          </div>
        </DynamicTooltip>
        <DynamicTooltip
          offset={{ x: 0, y: -30 }}
          content={<p className="text-sm !text-neutral-600">{title}</p>}
        >
          <Text
            weight="medium"
            className={clsx(
              'max-w-[75%] cursor-default truncate !text-primary-500',
              {
                '!text-neutral-350': disabled,
              },
            )}
          >
            {title}
          </Text>
        </DynamicTooltip>

        <Badge variant="outline">{internal ? 'Private' : 'Public'}</Badge>
      </div>

      <div className="flex items-center justify-end gap-4">
        {displayViewContent && (
          <Link to={`/sources/${id}/answers`}>
            <Button className="truncate bg-neutral-50 !px-3 !py-1 !text-[13px] !shadow-none">
              View
            </Button>
          </Link>
        )}

        <label htmlFor={`source-${id}`}>
          <Checkbox
            ref={undefined}
            name={`source-${id}`}
            id={`source-${id}`}
            onChange={onToggle}
            checked={disabled ? false : selected}
            disabled={disabled}
          />
        </label>
      </div>

      {disabled && (
        <div className="invisible absolute right-4 cursor-default rounded-md border border-secondary-400 bg-secondary-300 px-3 py-2 group-hover:visible">
          <Text variant="white">
            You can&apos;t add a private data source to a public Tool
          </Text>
        </div>
      )}
    </div>
  );
};

export default SourceCard;
