import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';
import { Link } from '@remix-run/react';

import { useUser } from '~/providers';
import { cn, widgetTemplates } from '~/utils';

import { Field } from '../Form';
import { Button } from '../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { OptionCard } from './Category';
import { TemplateSVG } from './TemplateSVG';

import type { FieldProps } from '../Form';
import type { WidgetTemplate } from '~/models';
import type { SearchInstanceObjectPath } from '~/utils';
import type { GroupBase } from 'react-select';

export const ToolField = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  adminOnly = false,
  label,
  description,
  noCard = false,
  containerClassName,
  ...props
}: FieldProps<Option, IsMulti, Group> & {
  adminOnly?: boolean;
  containerClassName?: string;
  name: SearchInstanceObjectPath;
  noCard?: boolean;
}) =>
  noCard ? (
    <Field
      name={name}
      id={name}
      {...props}
      {...(noCard ? { label } : { label: '' })}
      {...(noCard ? { description } : { description: '' })}
      {...(adminOnly && { LabelIcon: ShieldCheckIcon })}
    />
  ) : (
    <OptionCard className="gap-4">
      <div className="flex flex-col">
        <p className="text-sm font-semibold leading-normal text-neutral-600">
          {label}
        </p>
        <p className="text-sm leading-normal text-neutral-450">{description}</p>
      </div>

      <Field
        name={name}
        id={name}
        {...props}
        {...(noCard ? { label } : { label: '' })}
        {...(noCard ? { description } : { description: '' })}
        {...(adminOnly && { LabelIcon: ShieldCheckIcon })}
      />
    </OptionCard>
  );

type TemplateFieldProps = {
  isOnboardingFlow?: boolean;
  onSelectTemplate: (template: WidgetTemplate) => void;
  template: WidgetTemplate;
};
export const TemplateField = ({
  template,
  onSelectTemplate,
  isOnboardingFlow = false,
}: TemplateFieldProps) => {
  const {
    account: { features },
  } = useUser();

  return (
    <div className="flex justify-between gap-6">
      {widgetTemplates.map((toolTemplate) => (
        <ToolTemplateCard
          key={toolTemplate.id}
          onClick={() => onSelectTemplate(toolTemplate.id)}
          selected={template === toolTemplate.id}
          title={toolTemplate.title}
          name={toolTemplate.id}
          disabled={
            !!features.free_access?.amount && toolTemplate.id !== 'launcher'
          }
          isOnboardingFlow={isOnboardingFlow}
        />
      ))}
    </div>
  );
};

type ToolTemplateCardProps = {
  disabled?: boolean;
  isOnboardingFlow?: boolean;
  name: WidgetTemplate;
  onClick: () => void;
  selected?: boolean;
  title: string;
};

const ToolTemplateCard = ({
  title,
  selected = false,
  onClick,
  name,
  isOnboardingFlow = false,
  disabled = false,
}: ToolTemplateCardProps) => (
  <TooltipProvider delayDuration={0.1}>
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          onClick={onClick}
          className={cn('flex cursor-pointer flex-col gap-2', {
            'cursor-not-allowed': disabled,
          })}
          type="button"
          disabled={disabled}
        >
          <div
            className={cn('relative', {
              'opacity-50': !selected,
              'opacity-30': disabled,
            })}
          >
            <div
              className={cn(
                'absolute right-0 top-0 z-10 hidden -translate-y-1/2 translate-x-1/2 rounded-full',
                {
                  block: selected,
                },
              )}
            >
              <CheckCircleIcon className="h-6 w-6 rounded-full bg-white" />
            </div>

            <TemplateSVG template={name} />
          </div>

          <p
            className={cn('text-sm leading-normal', {
              'text-accent-purple-600 font-semibold': selected,
              'font-normal text-neutral-450': !selected,
            })}
          >
            {title}
          </p>
        </button>
      </TooltipTrigger>
      {disabled ? (
        <TooltipContent
          side={isOnboardingFlow ? 'top' : 'bottom'}
          className="max-w-80 cursor-default rounded-xl p-4"
        >
          <div className="flex flex-col gap-1">
            <p className="font-medium leading-normal text-neutral-600">
              Template only available on paid plans
            </p>
            <p className="text-[13px] text-neutral-500">
              Upgrade your plan today to get access to more widget types.
            </p>
            {isOnboardingFlow ? null : (
              <Button
                size="sm"
                variant="secondary"
                className="mt-2 text-[13px]"
                asChild
              >
                <Link to="/settings/plan">Upgrade now</Link>
              </Button>
            )}
          </div>
        </TooltipContent>
      ) : null}
    </Tooltip>
  </TooltipProvider>
);
