import { sourceTypeDict } from './constants';

import type { Source } from '~/models';

export function hasCredentials(
  type: Source['type'],
  configuration?: Source['configuration'],
): boolean {
  if (
    type === 'instant_answers' ||
    type === 'web_scrape' ||
    type === 'manual_upload'
  ) {
    return true;
  }

  if (configuration === undefined) {
    return false;
  }

  return sourceTypeDict[type]?.fields
    .filter(({ type }) => type === 'credentials')
    .every(({ name }) => {
      const cleanName = name.replace('[]', '');
      return (
        // @ts-expect-error
        !!configuration[cleanName]
      );
    });
}

export function getCleanHostname(domain: string) {
  let sourceUrl = domain.replace('http://', '');

  if (!sourceUrl.includes('https://')) {
    sourceUrl = `https://${sourceUrl}`;
  }

  let { hostname, pathname } = new URL(sourceUrl);

  return { hostname: hostname.replace('www.', ''), pathname };
}

export function isSyncing(sync_status: Source['sync_status']) {
  return (
    sync_status === 'pending_sync' ||
    sync_status === 'syncing' ||
    sync_status === 'pending_postprocess' ||
    sync_status === 'postprocessing'
  );
}

export function isSyncSuccessful(sync_status: Source['sync_status']) {
  return sync_status === 'succeeded' || sync_status === 'idle';
}

const baseWebScrapeDefaultConfig = {
  entrypoint_urls: [],
  allowed_domains: [],
  url_filters: [],
  disallowed_url_filters: [],
  no_follow_url_filters: [],
  no_index_url_filters: [],
  index_url_filters: [],
  follow_url_filters: [],
  sitemap_urls: [],
  clean_raw_html_regexes: [],
  auth: null,
  delay: 0,
  random_delay: 1,
  max_concurrent_requests: 20,
  request_timeout: 2,
  css_blacklist: [
    '[class*="breadcrumb"]',
    '[id*="breadcrumb"]',
    '[class*="broedkrumme"]',
    'nav',
    '[class*="nav"]',
    '[class*="main-nav"]',
    '[id*="nav"]',
    'aside',
    '[id*="sidebar"]',
    '[class*="sidebar"]',
    'footer',
    '[class*="footer"]',
    '[id*="footer"]',
    '[class*="cookie"]',
    '[id*="cookie"]',
    '[class*="Cookie"]',
    '[id*="Cookie"]',
    '[class*="c-site-header"]',
    '[class*="site-header"]',
  ],
  css_title_selectors: ['title'],
  css_whitelist: ['main', '[id*="main"]', '[role*="main"]', '[class*="main"]'],

  search_and_replace_patterns: [
    {
      url: '.*',
      find: '(?s)<title>\\s*(.*?)\\s*(?:\\|| - | – | &ndash;).*?</title>',
      replace: '<title>$1</title>',
      comment: "Strip '| text' or '- text' from title",
    },
  ],
  css_metadata_selectors: [],
  rich_scrape: false,
  disable_robot_tags: true,
  enable_url_parameters: false,
  parse_pdf_titles: false,
  enable_reader_mode: false,
  enable_url_case_insensitive: true,
  disable_sitemap_crawling: false,
  disable_display_none_pruning: true,
  disable_cookies: false,
  disable_following_canonical_urls: false,
  http_user_agent:
    'Mozilla/5.0 (X11; Linux x86_64; rv:88.0) Gecko/20100101 Firefox/88.0',
  dont_reuse_chrome_session: false,
  skip_tls_verification: false,
  additional_headers: null,
  limit_time: 0,
  limit_url: 0,
  partial_scrape_strategy: '',
  watchlist_configuration: {
    urls: [],
    limit_url: 0,
    limit_time: 0,
    limit_article: 0,
  },
  enable_verbose_logging: false,
  html_processing: {
    rules: [],
  },
};

export const freemiumWebScrapeDefaultConfig = {
  ...baseWebScrapeDefaultConfig,
  parse_pdf_titles: false,
  scrape_files: false,
  disable_content_deduplication: true,
  limit_article: 300,
};

export const nonFreemiumWebScrapeDefaultConfig = {
  ...baseWebScrapeDefaultConfig,
  parse_pdf_titles: true,
  scrape_files: true,
  disable_content_deduplication: false,
};
