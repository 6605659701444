import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/outline';
import { formatInTimeZone } from 'date-fns-tz';

import type { SortDirection } from '@tanstack/react-table';
import type { ChatConversation } from '~/models';
import type { HeroIcon } from '~/types';

const DAY_IN_SECONDS = 60 * 60 * 24;
const HOUR_IN_SECONDS = 60 * 60;

const relativeDateFormatter = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'short',
});

export const formatRelativeDate = (date: Date) => {
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;

  if (diffInSeconds < HOUR_IN_SECONDS) {
    return relativeDateFormatter.format(
      -Math.floor(diffInSeconds / 60),
      'minutes',
    );
  }

  if (diffInSeconds < DAY_IN_SECONDS / 2) {
    return relativeDateFormatter.format(
      -Math.floor(diffInSeconds / 3600),
      'hours',
    );
  }
  return formatInTimeZone(date, 'UTC', 'dd MMMMMM yyyy, hh:mm a');
};

export const feedbackFormatter = (reaction: ChatConversation['reaction']) => {
  if (!reaction) return '-';

  return reaction === 'thumbs_up' ? '👍' : '👎';
};

export const getSortIcon = (sortingState?: SortDirection | false): HeroIcon => {
  switch (sortingState) {
    case 'asc':
      return ArrowLongUpIcon;
    case 'desc':
      return ArrowLongDownIcon;
    default:
      return ArrowsUpDownIcon;
  }
};
