import { useNavigate } from '@remix-run/react';
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone-esm';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
} from '~/components/ui/context-menu';
import { cn, fadeIn } from '~/utils';

import LoadingSpinner from '../LoadingSpinner';
import {
  ActiveDropzoneText,
  ALLOWED_FILE_EXTENSIONS,
  DropzoneText,
} from '../ManualUploadDropzone';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';

import type { RankingInfo } from '@tanstack/match-sorter-utils';
import type {
  ColumnDef,
  FilterFn,
  Row,
  SortingState,
} from '@tanstack/react-table';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: rankings.CONTAINS,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export type TableCompactProps<T extends object> = {
  ExpandedComponent?: ({ row }: { row: T }) => JSX.Element;
  allowDrop?: boolean;
  bodyCellClassName?: string;
  bodyClassName?: string;
  bodyRowClassName?: string;
  canLoadMore?: boolean;
  className?: string;
  columns: Array<ColumnDef<T>>;
  contextMenu?: boolean;
  contextMenuContent?: (
    row: T,
    isAdmin?: boolean,
    features?: any,
    search?: string,
  ) => React.ReactNode;
  data: T[];
  defaultSorting?: SortingState;
  getBodyRowClassName?: (row: T) => string | undefined;
  globalFilter?: string;
  handleDrop?: (files: File[]) => void;
  headerCellClassName?: string;

  headerClassName?: string;
  hiddenColumns?: Record<string, boolean>;
  isLoading?: boolean;
  loadNextPage?: () => void;
  onRowClick?: (row: T) => void;
  selectedRowId?: string;
};

const TableCompact = <T extends object>({
  columns,
  data,
  globalFilter,
  hiddenColumns = {},
  defaultSorting = [],
  onRowClick,
  allowDrop,
  handleDrop,
  loadNextPage,
  canLoadMore,
  isLoading,
  selectedRowId,
  contextMenu,
  contextMenuContent,
}: TableCompactProps<T>) => {
  const navigate = useNavigate();
  const tableColumns = useMemo<Array<ColumnDef<T>>>(() => columns, [columns]);
  const tableData = useMemo(() => data, [data]);

  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: ALLOWED_FILE_EXTENSIONS,
  });

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      columnVisibility: hiddenColumns,
      sorting,
    },
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id?.toString(),
  });

  const tableContainerRef = React.useRef<HTMLTableElement>(null);

  const { rows } = table.getRowModel();
  const virtualRows = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    estimateSize: () => 38,
  });

  return (
    <motion.div
      className="h-full w-full overflow-auto"
      onScroll={(e) => {
        if (
          !isLoading &&
          canLoadMore &&
          Math.abs(
            e.currentTarget.scrollHeight -
              e.currentTarget.clientHeight -
              e.currentTarget.scrollTop,
          ) < 1
        ) {
          loadNextPage && loadNextPage();
        }
      }}
      {...fadeIn}
    >
      <Table ref={tableContainerRef}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="!border-solid bg-neutral-150/80 backdrop-blur-sm"
            >
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="pl-4 first:!pl-6">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {virtualRows.getVirtualItems().length ? (
            <>
              {virtualRows.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<T>;
                const rowContent = (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    className={cn({
                      'cursor-pointer': !!onRowClick,
                      'pointer-events-none bg-neutral-150':
                        selectedRowId === row.id,
                    })}
                    onClick={() => onRowClick && onRowClick(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="first:pl-6">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );

                // Conditionally wrap rowContent with ContextMenu based on `contextMenu` prop
                return contextMenu ? (
                  <ContextMenu key={row.id}>
                    <ContextMenuTrigger asChild className="w-fit">
                      {rowContent}
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                      {contextMenuContent
                        ? contextMenuContent(row.original, true, {}, '')
                        : null}
                    </ContextMenuContent>
                  </ContextMenu>
                ) : (
                  rowContent
                );
              })}

              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <LoadingSpinner />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {allowDrop ? (
                  <div
                    {...getRootProps()}
                    className="-mt-6 flex h-40 cursor-pointer flex-col items-center justify-center rounded-sm rounded-t-none border border-t-0 border-neutral-250 bg-neutral-0"
                    onClick={() => navigate('upload')}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <ActiveDropzoneText
                        isDragAccept={isDragAccept}
                        isDragReject={isDragReject}
                      />
                    ) : (
                      <DropzoneText />
                    )}
                  </div>
                ) : (
                  <p className="text-sm font-medium">
                    We couldn't find any data that match your search
                  </p>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </motion.div>
  );
};

export default TableCompact;
