import { InformationCircleIcon, SwatchIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useFetcher, useOutletContext } from '@remix-run/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { get as _get } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { useUser } from '~/providers';
import { cn, growHeightFadeIn } from '~/utils';
import { trackButtonClick } from '~/utils/analytics';

import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

import type { OutletContext } from '~/routes/_app.tools_.$toolId/view';
import type { HeroIcon } from '~/types';
import type { SearchInstanceObjectPath } from '~/utils';

type CategoryProps = {
  Icon?: HeroIcon;
  children: React.ReactNode;
  childrenWrapperClassName?: string;
  className?: string;
  collapsable?: boolean;
  defaultExpanded?: boolean;
  description?: string;
  title?: string;
};

export const Category = ({
  title,
  description,
  children,
  className,
  collapsable = false,
  defaultExpanded = false,
  childrenWrapperClassName,
  Icon,
}: CategoryProps) => {
  const [isExpanded, setIsExpanded] = useState(!collapsable || defaultExpanded);

  return (
    <OptionCard className={cn('overflow-hidden', className)}>
      {title && (
        <motion.div
          layout="position"
          className={clsx('flex items-center justify-between p-3 xl:p-6', {
            'cursor-pointer': collapsable,
          })}
          onClick={collapsable ? () => setIsExpanded(!isExpanded) : undefined}
        >
          <div className="flex items-center gap-4">
            {Icon && (
              <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-muted p-4">
                <Icon className="h-4 w-4 stroke-[1.5px] font-medium" />
              </div>
            )}
            <div className="flex flex-col justify-center">
              <p className="font-medium leading-normal text-neutral-600">
                {title}
              </p>

              {description && (
                <p className="hidden text-sm leading-[150%] text-neutral-450 xl:block">
                  {description}
                </p>
              )}
            </div>
          </div>

          {collapsable && (
            <div className="flex items-center justify-center">
              <Button
                className="aspect-square !p-2"
                variant="secondary"
                type="button"
              >
                {isExpanded ? (
                  <ChevronUpIcon className="h-3 w-3" />
                ) : (
                  <ChevronDownIcon className="h-3 w-3" />
                )}
              </Button>
            </div>
          )}
        </motion.div>
      )}

      <AnimatePresence mode="wait">
        {isExpanded && (
          <motion.div {...growHeightFadeIn} layout="position">
            <div
              className={cn(
                'flex flex-col border-0 border-solid [&>*:first-child]:border-t-0 [&>*]:border-t [&>*]:border-neutral-250 [&>*]:p-3 xl:[&>*]:p-6',
                {
                  '[&>*:first-child]:pt-0': title,
                },
                childrenWrapperClassName,
              )}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </OptionCard>
  );
};

type OptionCardProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  internal?: boolean;
};
export const OptionCard = ({
  children,
  className,
  internal = false,
  disabled = false,
}: OptionCardProps) => (
  <motion.div
    layout
    className={cn(
      'pointer-events-auto flex flex-col rounded-lg border border-neutral-250 bg-neutral-0 shadow xl:rounded-2xl',
      { 'bg-white-off': internal },
      { 'opacity-50': disabled },
      className,
    )}
  >
    {children}
  </motion.div>
);

type ToggleOptionProps = {
  Icon?: HeroIcon;
  checkToExpand?: boolean;
  children?: React.ReactNode;
  className?: string;
  description?: string;
  disabled?: boolean;
  expanded?: boolean;
  hideExpand?: boolean;
  internal?: boolean;
  isNew?: boolean;
  label: string;
  name: SearchInstanceObjectPath;
  noCard?: boolean;
  onChange?: (val: boolean) => void;
  purchased?: boolean;
  reverse?: boolean;
  tooltipInfo?: string;
};

export const ToggleOption = ({
  className,
  internal,
  disabled = false,
  noCard = false,
  ...props
}: ToggleOptionProps) =>
  noCard ? (
    <ToggleOptionContent {...props} disabled={disabled} />
  ) : (
    <OptionCard
      className={cn(className)}
      internal={internal}
      disabled={disabled}
    >
      <ToggleOptionContent {...props} disabled={disabled} />
    </OptionCard>
  );

const ToggleOptionContent = ({
  Icon,
  label,
  name,
  description,
  tooltipInfo,
  disabled = false,
  purchased = true,
  isNew = false,
  onChange,
}: ToggleOptionProps) => {
  const { tool, onChangeTool } = useOutletContext<OutletContext>();

  const value = Boolean(_get(tool, name, false));

  return (
    <label
      htmlFor={name}
      className="flex flex-grow cursor-pointer items-center justify-between gap-2 bg-neutral-0 transition first:rounded-t-2xl last:rounded-b-2xl hover:bg-neutral-50"
    >
      <div className="flex items-center gap-4">
        {Icon && (
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-muted p-4">
            <Icon className="h-4 w-4 stroke-[1.5px] font-medium" />
          </div>
        )}

        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <p
              className={cn(
                'text-sm font-medium leading-[150%] text-neutral-600',
                { 'text-base': Icon },
              )}
            >
              {label}
            </p>

            {tooltipInfo && (
              <QuestionTooltip>
                <p>{tooltipInfo}</p>
              </QuestionTooltip>
            )}

            {purchased ? null : <UpgradeFeatureTooltip configName={label} />}
          </div>

          <p className="text-sm leading-[150%] text-neutral-450">
            {description}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-3">
        {isNew && (
          <p className="rounded-sm bg-accent-purple-400 px-1 py-0.5 text-xs text-neutral-0">
            New
          </p>
        )}

        <Switch
          name={name}
          id={name}
          checked={value}
          disabled={disabled || !purchased}
          onCheckedChange={() => {
            onChangeTool({ [name]: !value });
            onChange?.(!value);
          }}
        />
      </div>
    </label>
  );
};

type QuestionTooltipProps = {
  children: React.ReactElement;
};
export const QuestionTooltip = ({ children }: QuestionTooltipProps) => (
  <TooltipProvider delayDuration={0.1}>
    <Tooltip>
      <TooltipTrigger asChild>
        <InformationCircleIcon className="h-4 w-4 text-neutral-450" />
      </TooltipTrigger>

      <TooltipPortal>
        <TooltipContent
          className="max-w-80 border-neutral-250 px-3 py-2 text-sm"
          side="right"
        >
          {children}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
);

export const UpgradeFeatureTooltip = ({
  configName,
}: {
  configName: string;
}) => {
  const { user, account } = useUser();
  const fetcher = useFetcher();

  const handleRequestFeature = useCallback(() => {
    fetcher.submit(
      { feature: configName },
      {
        method: 'post',
        action: '/action/email-feature',
      },
    );
  }, [fetcher, configName]);

  return (
    <TooltipProvider delayDuration={0.1}>
      <Tooltip>
        <TooltipTrigger type="button">
          <Badge variant="important">Upgrade Feature</Badge>
        </TooltipTrigger>

        <TooltipPortal>
          <TooltipContent className="rounded-2xl !border-neutral-250 !p-0">
            <div className="flex max-w-[500px] gap-2 p-4">
              <SwatchIcon className="h-5 w-5 text-accent-blue-300" />

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <p className="text-base font-medium leading-normal text-neutral-600">
                    Upgrade Feature
                  </p>
                  <p className="text-sm leading-normal text-neutral-550">
                    This feature is not available in your plan. <br /> You need
                    to upgrade to gain access.
                  </p>
                </div>
                <Button
                  className="w-fit"
                  type="button"
                  onClick={() => {
                    trackButtonClick({
                      buttonName: 'Upgrade Plan',
                      user,
                      account,
                      variant: 'primary',
                      location: 'ui_config',
                      additionalInfo: JSON.stringify({ feature: configName }),
                    });

                    handleRequestFeature();
                  }}
                >
                  Upgrade Plan
                </Button>
              </div>
            </div>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
