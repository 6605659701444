import { cn, formatDuration } from '~/utils';

import TableCompact from '../Table/TableCompact';
import { Badge } from '../ui/badge';
import { ColumnHeader } from './ColumnHeader';
import { feedbackFormatter, formatRelativeDate } from './utils';

import type { ColumnDef } from '@tanstack/react-table';
import type { ChatConversation } from '~/models';

export const columns: Array<ColumnDef<ChatConversation>> = [
  {
    id: 'messages',
    accessorKey: 'messages',
    header: (props) => <ColumnHeader label="Message" {...props} />,
    cell: ({ getValue }) => (
      <Badge variant="solid">
        <p className="max-w-48 truncate text-xs font-normal leading-normal text-neutral-600">
          {getValue<ChatConversation['messages']>()[0]?.message || ''}
        </p>
      </Badge>
    ),
    sortingFn: (a, b) => {
      const aMessage = a.original.messages[0]?.message || '';
      const bMessage = b.original.messages[0]?.message || '';
      // Use localeCompare to sort alphabetically
      return aMessage.localeCompare(bMessage);
    },
  },
  {
    id: 'started_at',
    accessorKey: 'started_at',
    header: (props) => <ColumnHeader label="Date" {...props} />,
    cell: ({ getValue }) => (
      <p className="text-sm leading-normal text-neutral-550">
        {formatRelativeDate(new Date(getValue<string>()))}
      </p>
    ),
    sortingFn: (a, b) => {
      const aDate = new Date(a.original.started_at);
      const bDate = new Date(b.original.started_at);
      return aDate.getTime() - bDate.getTime();
    },
  },
  {
    id: 'duration',
    accessorKey: 'duration',
    header: (props) => <ColumnHeader label="Duration" {...props} />,
    cell: ({ getValue }) => (
      <p className="text-sm leading-normal text-neutral-550">
        {formatDuration(getValue<number>())}
      </p>
    ),
  },
  {
    id: 'reaction',
    accessorKey: 'reaction',
    header: (props) => <ColumnHeader label="Feedback" {...props} />,
    cell: ({ getValue }) => {
      const reaction = getValue<ChatConversation['reaction']>();

      return (
        <p
          className={cn('truncate text-sm leading-normal text-neutral-550', {
            italic: !reaction,
          })}
        >
          {feedbackFormatter(reaction)}
        </p>
      );
    },
    sortingFn: (a, b) => {
      const getOrder = (reaction?: ChatConversation['reaction']) => {
        if (reaction === 'thumbs_up') return 2;
        if (reaction === 'thumbs_down') return 1;
        return 0;
      };

      return getOrder(a.original.reaction) - getOrder(b.original.reaction);
    },
  },
];

type Props = {
  canLoadMore?: boolean;
  chatHistory: ChatConversation[];
  isLoading?: boolean;
  loadNextPage?: VoidFunction;
  onRowClick?: (row: ChatConversation) => void;
  selectedRowId?: string;
};

export const ChatHistoryTable = ({ chatHistory, ...rest }: Props) => (
  <div className="flex h-full w-full border-t border-t-neutral-250">
    <TableCompact
      data={chatHistory}
      columns={columns}
      defaultSorting={[{ id: 'started_at', desc: true }]}
      {...rest}
    />
  </div>
);
