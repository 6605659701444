import { SwatchIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import { Plus, Users2Icon } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { useSidebarState, useUser } from '~/providers';
import { cn } from '~/utils';
import { trackButtonClick } from '~/utils/analytics';

export const AccountButton = () => {
  const { user, account } = useUser();
  const { minimise } = useSidebarState();

  const isFree = account.features.free_access?.amount === 1;
  const isPro = account.features.flex_access?.amount === 1;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          'group flex h-fit w-full items-center overflow-hidden rounded-md border border-primary-500 bg-primary-600 text-sm text-neutral-350 outline-none transition-all hover:border-primary-450 hover:bg-primary-550',
          { 'w-fit p-3': minimise },
        )}
      >
        <div
          className={cn(
            'flex items-center justify-center border-r border-primary-500 bg-primary-550 p-4 transition-all group-hover:border-primary-450 group-hover:bg-primary-500',
            { 'w-fit border-none bg-transparent p-0': minimise },
          )}
        >
          <SwatchIcon
            className={cn('h-4 w-4 text-accent-blue-100', {
              'h-4 w-4': minimise,
            })}
          />
        </div>
        <div
          className={cn('flex flex-col justify-start truncate px-3 text-left', {
            hidden: minimise,
          })}
        >
          <p className="truncate text-[13px] font-medium text-neutral-350">
            {account.title}
          </p>
          <p className="text-xs font-medium capitalize text-neutral-400">
            {isFree ? 'Raffle Go' : account.plan_name}
          </p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="start"
        className="z-[99999] rounded-md border border-primary-500 bg-primary-550 p-2 shadow-md"
      >
        <DropdownMenuLabel className="mb-2 w-56 p-2 text-[13px] font-normal text-neutral-400">
          Your workspace is currently using our{' '}
          <span className="font-medium capitalize text-accent-purple-200">
            {isFree ? 'Raffle Go' : account.plan_name}
          </span>{' '}
          plan.
        </DropdownMenuLabel>
        {(isFree || isPro) && (
          <Link to="settings/plan">
            <DropdownMenuItem
              onClick={() =>
                trackButtonClick({
                  buttonName: 'Upgrade Plan',
                  user,
                  account,
                  variant: 'secondary',
                  location: 'account_button',
                })
              }
              className="flex flex-row p-2 text-[13px] text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
            >
              Upgrade Plan
            </DropdownMenuItem>
          </Link>
        )}
        <DropdownMenuSeparator className="my-2 bg-primary-500" />
        <Link to="settings/general">
          <DropdownMenuItem className="flex flex-row p-2 text-[13px] text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250">
            Account Settings
          </DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator className="my-2 bg-primary-500" />

        <div className="font-normal text-neutral-350">
          <Link to="/settings/users/invite">
            <DropdownMenuItem
              onClick={() =>
                trackButtonClick({
                  buttonName: 'Invite',
                  user,
                  account,
                  variant: 'secondary',
                  location: 'account_button',
                })
              }
              className="group flex flex-row justify-between p-2 text-[13px] hover:!bg-primary-500 hover:!text-neutral-250"
            >
              Invite people
              <Plus className="h-3 w-3 text-neutral-450 group-hover:!text-neutral-300" />
            </DropdownMenuItem>
          </Link>
          <Link to="/settings/users/">
            <DropdownMenuItem className="group flex flex-row justify-between p-2 text-[13px] hover:!bg-primary-500 hover:!text-neutral-250">
              Users and Roles
              <Users2Icon className="h-3 w-3 text-neutral-450 group-hover:!text-neutral-300" />
            </DropdownMenuItem>
          </Link>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
