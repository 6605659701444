import { NavLink } from '@remix-run/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { cn } from '~/utils';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

type NavTabsProps = {
  children: React.ReactNode;
  className?: string;
};
export const NavTabs = ({ className, children }: NavTabsProps) => (
  <div className={clsx('flex gap-2 bg-neutral-50 px-4 py-3', className)}>
    {children}
  </div>
);

const sharedTabChipStyle = (isActive: boolean, disabled: boolean) =>
  clsx(
    'flex items-center justify-center rounded-full border border-transparent px-3 py-1.5 text-center text-sm font-medium leading-normal transition-all hover:text-neutral-600',
    {
      'bg-transparent text-neutral-500': !isActive,
      '!border-neutral-250 bg-neutral-0 text-neutral-600 shadow-md': isActive,
      'pointer-events-none !cursor-not-allowed': disabled,
    },
  );

type NavTabProps = {
  className?: string;
  disabled?: boolean;
  label: string;
  to: string;
};
export const NavTab = ({ label, to, className, disabled }: NavTabProps) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      clsx(sharedTabChipStyle(isActive, !!disabled), className)
    }
  >
    {label}
  </NavLink>
);

type ChipTabProps = {
  disabled?: boolean;
  isActive: boolean;
  label: string;
  onClick: () => void;
  tooltipInfo?: string;
};

export const ChipTab = ({
  label,
  disabled = false,
  isActive,
  onClick,
  tooltipInfo,
}: ChipTabProps) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <button
          onClick={!disabled ? onClick : undefined}
          className={cn(
            'relative rounded-full px-3 py-1.5 text-sm font-medium leading-normal text-neutral-600 transition',
            {
              'text-neutral-450 hover:text-neutral-600': !isActive,
              'cursor-not-allowed text-neutral-400 hover:bg-destructive/10':
                disabled,
            },
          )}
          type="button"
        >
          {isActive && (
            <motion.div
              layoutId="active-pill-addons"
              className="absolute inset-0 rounded-full border border-neutral-250 !bg-neutral-0 shadow-md"
              transition={{ type: 'spring', bounce: 0.15, duration: 0.3 }}
            />
          )}
          <span className="relative">{label}</span>
        </button>
      </TooltipTrigger>
      {disabled && tooltipInfo && (
        <TooltipContent>{tooltipInfo}</TooltipContent>
      )}
    </Tooltip>
  </TooltipProvider>
);
