import { analytics } from '~/services/segment.client';

import type { UserProviderValue } from '~/providers';

type TrackButtonClickProps = {
  account?: UserProviderValue['account'];
  additionalInfo?: string;
  buttonName:
    | 'Upgrade Plan'
    | 'Upgrade to pro'
    | 'Upgrade to scale'
    | 'Upgrade to enterprise'
    | 'Install UI'
    | 'Preview UI'
    | 'Copy'
    | 'Invite'
    | 'Docs';
  location?:
    | 'top_bar'
    | 'plan_card'
    | 'floating_dock'
    | 'ui_config'
    | 'ui_overview'
    | 'unknown'
    | 'sidebar'
    | 'account_button'
    | 'install_modal'
    | 'feature_placeholder'
    | 'settings_plan_overview'
    | 'index_creation';
  user?: UserProviderValue['user'];
  variant?: 'primary' | 'secondary' | 'link';
};

export function trackButtonClick({
  account,
  buttonName,
  location,
  user,
  variant,
  additionalInfo,
}: TrackButtonClickProps) {
  if (!user?.email.includes('@raffle.ai')) {
    analytics.track('Button Clicked', {
      // Button Details
      button_name: buttonName,
      button_variant: variant || 'default',
      button_location: location || 'unknown',
      industry: account?.industry || 'unknown',
      additional_info: additionalInfo,

      page: window.location.pathname,

      // User & Account Details
      account_id: account?.id.toString().padStart(8, '0') || 'unknown',
      role: user?.roles[0] || 'unknown',
      subscription_plan: account?.plan_name || 'unknown',
    });
  }
}

type TrackVideoClickProps = {
  account?: UserProviderValue['account'];
  additionalInfo?: string;
  location?: 'consumption_card';
  user?: UserProviderValue['user'];
  videoName: 'Install Guide';
};

export function trackVideoClick({
  account,
  videoName,
  location,
  user,
  additionalInfo,
}: TrackVideoClickProps) {
  if (!user?.email.includes('@raffle.ai')) {
    analytics.track('Video Played', {
      // Video Details
      video_name: videoName,
      button_location: location || 'unknown',
      industry: account?.industry || 'unknown',
      additional_info: additionalInfo,

      page: window.location.pathname,

      // User & Account Details
      account_id: account?.id.toString().padStart(8, '0') || 'unknown',
      role: user?.roles[0] || 'unknown',
      subscription_plan: account?.plan_name || 'unknown',
    });
  }
}

type TrackInputInteractionProps = {
  account?: UserProviderValue['account'];
  additionalInfo?: string;
  inputName: 'Scrape URL';
  location?: 'onboarding_flow';
  type: 'focus' | 'change';
  user?: UserProviderValue['user'];
  value?: string;
};

export function trackInputInteraction({
  account,
  inputName,
  location,
  user,
  additionalInfo,
  type,
}: TrackInputInteractionProps) {
  if (!user?.email.includes('@raffle.ai')) {
    analytics.track('Button Clicked', {
      // Input Details
      input_name: inputName,
      button_location: location || 'unknown',
      industry: account?.industry || 'unknown',
      additional_info: additionalInfo,
      interaction_type: type,

      page: window.location.pathname,

      // User & Account Details
      account_id: account?.id.toString().padStart(8, '0') || 'unknown',
      role: user?.roles[0] || 'unknown',
      subscription_plan: account?.plan_name || 'unknown',
    });
  }
}
