import { cn } from '~/utils';

import { getSortIcon } from './utils';

import type { HeaderContext } from '@tanstack/react-table';
import type { ChatConversation } from '~/models';

export const ColumnHeader = ({
  column,
  label,
}: HeaderContext<ChatConversation, unknown> & { label: string }) => {
  const sortingState = column.getIsSorted();
  const Icon = getSortIcon(sortingState);
  return (
    <div
      className={cn(
        'flex h-fit w-fit -translate-x-2 cursor-pointer select-none items-center gap-1 rounded-md border border-transparent px-2 py-1 text-[13px]',
        {
          'border-neutral-250 bg-neutral-200 text-accent-blue-300':
            sortingState,
          'hover:border-neutral-250 hover:bg-neutral-200 hover:text-neutral-600':
            !sortingState,
        },
      )}
      onClick={() => column.toggleSorting(sortingState === 'asc')}
    >
      <span>{label}</span>{' '}
      <Icon
        className={cn('h-3 w-3', {
          'text-neutral-400': !sortingState,
        })}
      />
    </div>
  );
};
